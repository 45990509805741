<template>

  <div>
    <div v-show="createMode">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Create New Budget
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitPreviewExcel">
        <validation-observer
          ref="budgetSetupForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class text-uppercase">Step 1</span>
                Define your Budgetary Exercise
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Cost Centre"
                  vid="costCenter"
                  rules="required"
                >
                  <b-form-group
                    label="Cost Centre"
                    label-for="budget-setup-cost-center"
                    label-cols-md="4"
                    :state="(errors.length > 0 || costCenterValidation) ? false : null"
                  >
                    <v-select
                      id="budget-setup-cost-center"
                      v-model="costCenter"
                      label="name"
                      placeholder="Select from list"
                      :options="costCenterOptions"
                      :reduce="name => name._id"
                      :clearable="false"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="costCenterValidation"
                      class="text-danger"
                    >
                      {{ costCenterError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Budgetary Period"
                  vid="budgetaryPeriod"
                  rules="required"
                >
                  <b-form-group
                    label="Budgetary Period"
                    label-for="budget-setup-budgetary-period"
                    label-cols-md="4"
                    :state="(errors.length > 0 || budgetaryPeriodValidation) ? false : null"
                  >
                    <v-select
                      id="budget-setup-budgetary-period"
                      v-model="budgetaryPeriod"
                      label="name"
                      placeholder="Select from list"
                      :options="budgetaryPeriodOptions"
                      :reduce="name => name._id"
                      :clearable="false"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #list-header>
                        <b-button
                          v-if="canViewThisAction('create', 'BudgetPeriod')"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.create-new-budgetary-modal
                          block
                          type="button"
                          variant="flat-primary"
                          class="border-bottom pl-2 text-left"
                        >
                          Create New Budgetary Period
                        </b-button>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #option="{ name, start, end }">
                        <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                        <br>
                        <span
                          style="font-weight: 500;font-size: 12px;"
                          class="text-muted"
                        >
                          {{ dateFormat(start) }} - {{ dateFormat(end) }}
                        </span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="budgetaryPeriodValidation"
                      class="text-danger"
                    >
                      {{ budgetaryPeriodError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Expense Category Type"
                  vid="expenseCategoryType"
                  rules="required"
                >
                  <b-form-group
                    label="Expense Category Type"
                    label-for="budget-setup-expense-category-type"
                    label-cols-md="4"
                    :state="(errors.length > 0 || expenseCategoryTypeValidation) ? false : null"
                  >
                    <v-select
                      id="budget-setup-expense-category-type"
                      v-model="expenseCategoryType"
                      label="name"
                      placeholder="Select from list"
                      :options="expenseCategoryTypeOptions"
                      :reduce="index => index"
                      :clearable="false"
                      @input="getExpenseCategories()"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="expenseCategoryTypeValidation"
                      class="text-danger"
                    >
                      {{ expenseCategoryTypeError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />
            </b-row>
          </b-card>
          <b-card
            ref="repeatCard"
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class text-uppercase">Step 2</span>
                Follow Budget Template Guide
              </h3>
            </template>
            <div class="file-upload-step-block">
              <div class="instruction-text-block">
                <b>To upload a new budget file (.csv), follow these simple steps:</b>
                <li class="instruction_item">
                  Do not change the Cost Center and Budgetary Period fields in row 1 and 2. This is for your reference only.
                </li>
                <li class="instruction_item">
                  Fill in the annual budget amount in column D, from row 5 onwards. (i.e. start from cell D5).
                </li>
                <li class="instruction_item">
                  Exclude expense categories for this budget by deleting the entire row, or leaving its column D cell blank or putting a 0 instead.
                </li>
              </div>
              <div class="instruction-img-block my-1">
                <div>
                  <b-img
                    class="img-fluid"
                    :src="excelSheetBody"
                    alt="excel sheet"
                  />
                </div>
                <div>
                  <b-img
                    class="img-fluid"
                    :src="excelSheetFooter"
                    alt="excel sheet"
                  />
                </div>
              </div>
            </div>
            <div class="add_item">
              <p><b>Complete step 1 to download template</b></p>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                :disabled="invalid"
                @click="downloadExcel()"
              >
                <span>Download Template</span>
                <span v-if="!invalid">
                  {{ `(${resolveCostCenterName(costCenter)}, ${resolveBudgetaryPeriodName(budgetaryPeriod)}, ${expenseCategoryType})` }}
                </span>
              </b-button>
            </div>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class text-uppercase">Step 3</span>
                Upload Your Budget Template
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop file here or click to upload
                    </h3>
                    <div class="subtitle">
                      Files should not bigger than 20mb. XLSX only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'budgeting-budget-setup' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                :disabled="invalid || !uploadedFileArray.length"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
    <div v-show="!createMode">
      <div>
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="7"
            md="9"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 class="custom-header-title float-left pr-1 mb-0">
                  Preview New Budget
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Table Container Card -->

        <b-card
          class="mb-3 p-2 calllog-details serviceForm_details"
        >
          <b-table-simple
            borderless
            responsive
            class="particulars_of_deceased d-none d-md-block"
          >
            <b-tbody>
              <b-tr>
                <b-th width="230px">
                  Cost Centre
                </b-th>
                <b-td>{{ resolveCostCenterName(costCenter) }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Budgetary Period
                </b-th>
                <b-td>{{ resolveBudgetaryPeriodName(budgetaryPeriod) }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Expense Category Type
                </b-th>
                <b-td>
                  {{ expenseCategoryType }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-table-simple
            borderless
            responsive
            class="particulars_of_deceased d-block d-md-none"
          >
            <b-tbody>
              <b-tr>
                <b-th width="230px">
                  Cost Centre
                </b-th>
                <b-td>{{ resolveCostCenterName(costCenter) }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Budgetary Period
                </b-th>
                <b-td> {{ resolveBudgetaryPeriodName(budgetaryPeriod) }} </b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Expense Category Type
                </b-th>
                <b-td>
                  {{ expenseCategoryType }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>

        <b-card
          no-body
          class="mb-3 p-2"
        >
          <b-table
            ref="refAllRelatedInventoryRequestsItemTable"
            class="position-relative has_padding event_list"
            :items="budgetSetupData"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            no-sort-reset
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(stringID)="data">
              <span
                class="text-bold-black"
                :class="data.item.error ? 'text-danger' : ''"
              >{{ data.item.stringID }}</span>
            </template>
            <template #cell(code)="data">
              <span
                :class="data.item.error ? 'text-danger' : ''"
              >{{ data.item.code }}</span>
            </template>
            <template #cell(name)="data">
              <span
                :class="data.item.error ? 'text-danger' : ''"
              >{{ data.item.name }}</span>
            </template>
            <template #cell(allocated)="data">
              <span
                :class="data.item.error ? 'text-danger' : ''"
              >{{ numberFormat(data.item.allocated) }}</span>
            </template>
          </b-table>
        </b-card>

        <b-card v-if="errorItems.length > 0">
          <p>A few errors found, please check your data again:</p>
          <ul style="list-style: none; padding: 0; color: #D91B35;">
            <li
              v-for="error in errorItems"
              :key="error.stringID"
            >
              <span v-if="error.allocated < 0">{{ `${error.stringID} cannot be less than S$0` }}</span>
              <span v-else>{{ `${error.stringID} and budget code ${error.code} not match in Expense Categories database` }}</span>
            </li>
          </ul>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="createMode = true"
            >
              Reupload
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="button"
              :disabled="errorItems.length > 0"
              @click="importToSystem()"
            >
              <feather-icon
                icon="ArrowRightIcon"
                class="mr-50"
              />
              <span class="align-middle">Import to system</span>
            </b-button>
          </li>
        </b-nav>
      </div>
    </div>

    <!-- create new budgetary period modal -->
    <b-modal
      id="create-new-budgetary-modal"
      ref="create-new-budgetary-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Create New Budgetary Period"
      @cancel="closeCreateNewPeriodModal"
      @ok="submitBudgetaryPeriodCreateForm"
    >
      <b-form @submit.prevent="submitBudgetaryPeriodCreateForm">
        <validation-observer
          ref="allBudgetaryPeriodCreateForm"
        >
          <b-form-group
            label="Name*"
            label-for="budgetary-period-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="budgetary-period-name"
                v-model="name"
                placeholder="Enter a name"
                :state="(errors.length > 0 || nameValidation) ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div class="period-date">
            <b-form-group
              label="Start Date*"
              label-for="budgetary-period-start-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                vid="startDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-start-date"
                    v-model="startDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select a Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="startDateValidation"
                  class="text-danger"
                >
                  {{ startDateError }}
                </small>
              </validation-provider>
            </b-form-group>
            <div style="margin-top: 40px;">
              <feather-icon
                icon="ArrowRightIcon"
                size="20"
              />
            </div>
            <b-form-group
              label="End Date*"
              label-for="budgetary-period-end-date"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date"
                vid="endDate"
                rules="required"
              >
                <b-input-group>
                  <flat-pickr
                    id="budgetary-period-end-date"
                    v-model="endDate"
                    class="form-control flat-pickr-group"
                    placeholder="Select a Date"
                    :config="flatPickrConfigWithoutTime"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="endDateValidation"
                  class="text-danger"
                >
                  {{ endDateError }}
                </small>
              </validation-provider>
            </b-form-group>
          </div>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create new budgetary period modal -->

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BNav, BImg, VBTooltip, BFormInput, BInputGroup, BInputGroupAppend,
  BTableSimple, BTable, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { required } from '@validations'
import { read, writeFile, utils } from 'xlsx'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContentCustom from './ToastificationContentCustom.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BNav,
    BImg,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTable,
    vSelect,
    flatPickr,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  mixins: [heightTransition],
  data() {
    return {
      // eslint-disable-next-line global-require, import/no-unresolved
      excelSheetBody: require('@/assets/images/excel_screenshot/screenshot_1_body.jpg'),
      // eslint-disable-next-line global-require, import/no-unresolved
      excelSheetFooter: require('@/assets/images/excel_screenshot/screenshot_1_footer.jpg'),
      createMode: true,
      savedData: false,
      costCenter: '',
      costCenterValidation: false,
      costCenterError: 'Valid Cost Centre is required',
      budgetaryPeriod: '',
      budgetaryPeriodValidation: false,
      budgetaryPeriodError: 'Valid budgetary period is required',
      expenseCategoryType: '',
      expenseCategoryTypeValidation: false,
      expenseCategoryTypeError: 'Valid expense category type is required',
      // new budgetary period form validation
      name: '',
      nameError: 'Name is required',
      nameValidation: false,
      startDate: '',
      startDateValidation: false,
      startDateError: 'Start Date is required',
      endDate: '',
      endDateValidation: false,
      endDateError: 'End Date is required',
      // /. new budgetary period form validation
      costCenterOptions: [],
      budgetaryPeriodOptions: [],
      expenseCategoryTypeOptions: [
        'Operating Expenses',
        'CAPEX (Fixed Assets)',
        'CAPEX (Lease Assets)',
        'Projects',
      ],
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        maxFiles: 1,
      },
      uploadedFileArray: [],
      fetchFileArray: [],
      attachments: [],
      budgetSetupData: [],
      errorItems: [],
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: false,
      tableColumns: [
        {
          key: 'stringID',
          label: 'id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'budget code',
          sortable: false,
        },
        {
          key: 'name',
          label: 'expense category',
          sortable: false,
        },
        {
          key: 'allocated',
          label: 'annual budget amount (S$)',
          sortable: false,
        },
      ],
      // validation rules
      required,
    }
  },

  mounted() {
    this.initTrHeight()
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            // localStorage.removeItem('costCenter')
            // localStorage.removeItem('budgetaryPeriod')
            // localStorage.removeItem('expenseCategoryType')
            // localStorage.removeItem('budgetSetupAttachments')
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  beforeMount() {
    this.$http.get('budget/cost-center/respond-with/name-and-id')
      .then(response => {
        this.costCenterOptions = response.data.costCenters
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('budget/budget-period/respond-with/name-and-id')
      .then(response => {
        this.budgetaryPeriodOptions = response.data.budgetPeriods
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    canViewThisAction,
    processFile(file) {
      const acceptedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 20 * 1024 * 1024) {
            const reader = new FileReader()
            reader.readAsArrayBuffer(element)

            reader.onload = event => {
              let data = event.target.result
              data = new Uint8Array(data)
              const workbook = read(data)
              const firstSheetName = workbook.SheetNames[0]
              const worksheet = workbook.Sheets[firstSheetName]
              this.uploadedFileArray = utils.sheet_to_json(worksheet, { header: ['stringID', 'code', 'name', 'allocated'], range: 4, defval: '' })
              if (!this.uploadedFileArray.length) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Excel sheet is empty! please fill up the value and try again.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
            // attach file in database
            const fileReader = new FileReader()
            fileReader.readAsDataURL(element)
            fileReader.onload = event => {
              const fileObj = {}
              fileObj.name = element.name
              fileObj.description = ''
              fileObj.data = event.target.result
              fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
              fileObj.type = element.type
              this.attachments.push(fileObj)
            }
          }
        }
      })
    },
    fileRemoved() {
      this.uploadedFileArray = []
      this.attachments = []
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    getExpenseCategories() {
      const formData = new FormData()
      formData.append('type', this.expenseCategoryType)
      this.$http.post('budget/budget/expense-categories-from-type', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.fetchFileArray = response.data.expenseCategories
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.repeatCard.scrollHeight)
      // })
    },

    resolveCostCenterName(id) {
      const found = this.costCenterOptions.find(ob => ob._id === id)
      if (found) {
        return found.name
      }
      return ''
    },

    resolveBudgetaryPeriodName(id) {
      const found = this.budgetaryPeriodOptions.find(ob => ob._id === id)
      if (found) {
        return found.name
      }
      return ''
    },

    resolveBudgetaryPeriodDate(id) {
      const found = this.budgetaryPeriodOptions.find(ob => ob._id === id)
      if (found) {
        return `${this.dateFormat(found.start)} - ${this.dateFormat(found.end)}`
      }
      return ''
    },

    downloadExcel() {
      if (this.fetchFileArray.length) {
        const data = []
        this.fetchFileArray.forEach(obj => {
          data.push({
            stringID: obj.stringID ? obj.stringID.trim() : '',
            code: obj.code ? obj.code.trim() : '',
            name: obj.name,
            allocated: '0.00',
          })
        })
        const worksheet = utils.aoa_to_sheet([
          ['Cost Center', `${this.resolveCostCenterName(this.costCenter)}`],
          ['Budgetary Period', `${this.resolveBudgetaryPeriodDate(this.budgetaryPeriod)}`],
          [],
          ['ID', 'Budget Code', 'Expense Category', 'Annual Budgeted Amount (S$)'],
        ])
        utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
        worksheet['!cols'] = [{ wch: 15 }, {}, { wch: 30 }]
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, `${this.expenseCategoryType}`)
        writeFile(workbook, `${this.resolveCostCenterName(this.costCenter)}-${this.resolveBudgetaryPeriodName(this.budgetaryPeriod)}-${this.expenseCategoryType}.xlsx`, { compression: true })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Result not found! Please change the input field value and try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    submitPreviewExcel() {
      if (this.uploadedFileArray.length) {
        // localStorage.setItem('costCenter', this.costCenter)
        // localStorage.setItem('budgetaryPeriod', this.budgetaryPeriod)
        // localStorage.setItem('expenseCategoryType', this.expenseCategoryType)
        // localStorage.setItem('budgetItems', JSON.stringify(this.uploadedFileArray))
        // localStorage.setItem('fetchedBudgetItems', JSON.stringify(this.fetchFileArray))
        // localStorage.setItem('budgetSetupAttachments', JSON.stringify(this.attachments))
        // this.savedData = true
        // this.$router.push({ name: 'budgeting-budget-setup-preview' })
        this.uploadedFileArray.forEach(i => {
          // eslint-disable-next-line no-param-reassign
          i.allocated = this.floatNumber(i.allocated)
          // eslint-disable-next-line no-param-reassign
          i.stringID = i.stringID ? i.stringID.trim() : ''
        })
        this.budgetSetupData = this.uploadedFileArray
        this.errorItems = []
        this.createMode = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excel file is empty! please fill up all fields and try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    importToSystem() {
      this.budgetSetupData.forEach(uploadedData => {
        const matchData = this.fetchFileArray.find(previousData => (previousData.stringID || '').trim() === (uploadedData.stringID || '').trim() && (previousData.code || '').trim() === (uploadedData.code || '').trim())
        if (matchData) {
          if (Number(uploadedData.allocated) < 0) {
            this.errorItems.push({
              stringID: uploadedData.stringID,
              code: uploadedData.code,
              name: uploadedData.name,
              allocated: uploadedData.allocated,
            })
            // eslint-disable-next-line no-param-reassign
            uploadedData.error = true
          }
        } else {
          this.errorItems.push({
            stringID: uploadedData.stringID,
            code: uploadedData.code,
            name: uploadedData.name,
            allocated: uploadedData.allocated,
          })
          // eslint-disable-next-line no-param-reassign
          uploadedData.error = true
        }
      })

      if (!this.errorItems.length) {
        const formData = new FormData()
        const stringIDs = []
        this.budgetSetupData.forEach(sID => {
          stringIDs.push(sID.stringID)
        })
        formData.append('expenseCategoryType', this.expenseCategoryType)
        formData.append('costCenter', this.costCenter)
        formData.append('budgetPeriod', this.budgetaryPeriod)
        let totalAmount = this.budgetSetupData.reduce((prev, current) => prev + Number(current.allocated), 0)
        totalAmount = this.floatNumber(totalAmount)
        formData.append('budgetAmount', totalAmount)
        formData.append('categoryIDs', JSON.stringify(stringIDs))
        formData.append('items', JSON.stringify(this.budgetSetupData))
        formData.append('attachments', JSON.stringify(this.attachments))
        this.$http.post('budget/budget/store', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            this.savedData = true
            this.$swal({
              title: 'Budget Setup Created Successfully!',
              html: '',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/success.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  this.$router.push({ name: 'budgeting-budget-setup', query: { costCenter: this.costCenter } })
                }
              })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },

    closeCreateNewPeriodModal() {
      this.$root.$emit('bv::hide::modal', 'create-new-budgetary-modal', '')
    },

    submitBudgetaryPeriodCreateForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allBudgetaryPeriodCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('start', this.startDate)
          formData.append('end', this.endDate)
          formData.append('status', 'active')
          this.$http.post('budget/budget-period/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.closeCreateNewPeriodModal()
              const bPeriod = response.data.data
              this.budgetaryPeriodOptions.push({
                _id: bPeriod._id,
                name: bPeriod.name,
                start: bPeriod.start,
                end: bPeriod.end,
              })

              this.budgetaryPeriod = bPeriod._id

              const toastMessage = `Budgetary Period name is <span class="text-bold-black">${response.data.data.name}</span>.`
              this.$toast({
                component: ToastificationContentCustom,
                props: {
                  title: 'Budgetary Period is created!',
                  text: toastMessage,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.name = ''
              this.startDate = ''
              this.endDate = ''
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                  if (validationError.param === 'start') {
                    this.startDateError = validationError.msg
                    this.startDateValidation = true
                  }
                  if (validationError.param === 'end') {
                    this.endDateError = validationError.msg
                    this.endDateValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
<style scoped>
.modal .form-control {
     margin: 0;
}
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
 .instruction_item {
       list-style-type: decimal;
 }
</style>
